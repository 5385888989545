<template>
  <div>
    移动端暂未开发本功能，请您使用电脑网页端使用！！！
    <MobileTabbar />
  </div>
</template>

<script>
import MobileTabbar from '@/components/mobile/MobileTabbar'
export default {
  name: 'MobileCasesList',
  components: {
    MobileTabbar
  }

}
</script>

<style scoped>

</style>
